import * as  _EventEmitter from 'Vendors/event-emitter/EventEmitter';
global.EventEmitter = _EventEmitter;
/**************************************************
***       Deferred scripts loader               *** 
**************************************************/

// for test Promise bundling
//(new Promise((resolve, reject) => {
//    console.log('Promise >>>>');
//    resolve();
//}));
function AppLoaderModel() {
    var self = this;
    // inherit from EventEmitter
    //EventEmitter.call(self);

    self._loaded = false;
    self._ready = false;

    self.isReady = function () {
        return self._ready;
    }
    self.setReady = function () {
        if (self.isReady()) {
            console.warn('AppLoaderModel.setReady rised again!');
            return;
        }

        var set_ready = function(){
            self._ready = true;
            self.trigger('ready');
        };
        if (self.isLoaded()) {
            set_ready();
        } else {
            self.loaded(set_ready);
        }
    };

    self.isLoaded = function () {
        return self._loaded;
    }
    self.setLoaded = function () {
        if (self.isLoaded()) {
            console.warn('AppLoaderModel.setLoaded rised again!');
            return;
        }
        self._loaded = true;
        self.trigger('loaded');
    };
}
//AppLoaderModel.prototype = Object.clone(EventEmitter.prototype);
AppLoaderModel.prototype = Object.create(EventEmitter.prototype);
AppLoaderModel.prototype.ready = function (listener) {
    var self = this;
    if (self.isReady()) {
        listener.apply(document, self);
    } else {
        this.addListener('ready', function () {
            listener.apply(document, self);
            //var loader = this;
            //(function (w) {
            //    listener.apply(w, loader);
            //}).call(window);
        });
    }
};
AppLoaderModel.prototype.loaded = function (listener) {
    var self = this;
    //(function (w, loader) {
    if (self.isLoaded()) {
        listener.apply(window, self);
    } else {
        this.addListener('loaded', function () {
            listener.apply(window, self);
        });
        //}).call(window, self);
    }
};
AppLoaderModel.prototype.raf = function (cb, delay) {
    var _self = this;
    if (typeof cb === "undefined" || typeof cb !== "function")
        return;

    if (typeof delay !== "undefined") {
        window.setTimeout(function () {
            _self.raf(cb);
        }, delay);
        return;
    }

    var raf = requestAnimationFrame || mozRequestAnimationFrame ||
        webkitRequestAnimationFrame || msRequestAnimationFrame;
    if (raf) raf(cb);
    else {
        let delayTask = /MSIE ([6-9]+\.\d+);/.test(navigator.userAgent) ? 8 : 0;
        window.setTimeout(cb, delayTask);
    };
};
//AppLoaderModel.prototype.loadScript = function (src, cb) {
//    if (typeof cb === "undefined")
//        cb = function () { };
//    loadScripts([src], cb, { async: true, defer: true });    
//};
//var AppLoader = new AppLoaderModel();
global.AppLoader = new AppLoaderModel();


//function loadJS(u) { var r = document.getElementsByTagName("script")[0], s = document.createElement("script"); s.src = u; r.parentNode.insertBefore(s, r); }
function loadScripts(array, callback, options) {
    var loader = function (src, handler) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        script.async = false;
        if (options) {
            if (options.async) {
                script.async = true;
            }
            if (options.defer) {
                script.defer = "defer";
            }
        }

        script.onload = script.onreadystatechange = function () {
            script.onreadystatechange = script.onload = null;
            //console.log('>>> loader onload >>> ' + src);
            if (/MSIE ([6-9]+\.\d+);/.test(navigator.userAgent)) window.setTimeout(function () { handler(); }, 8, this);
            else handler();
        }
        var head = document.getElementsByTagName("head")[0];
        (head || document.body).appendChild(script);
    };
    (function run() {
        if (array.length != 0) {
            loader(array.shift(), run);
        } else {
            callback && callback();
        }
    })();
}

/* // Example of usage
    if (listScripts.length > 0) {
        console.log("listScripts length = " + listScripts.length);
        loadScripts(listScripts, function () {
            console.log("listScripts initPictures");
            initPictures();
        }, { defer: true });
    }
*/


(function () {
    var insertContentScripts = function (listContentScripts) {
        if (!listContentScripts || listContentScripts.length < 0)
            return;

        for (var i = 0; i < listContentScripts.length; i++) {
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.textContent = listContentScripts[i];
            document.head.appendChild(s);
        }
    };

    var loadDeferredScriptGroup = function (addScriptsNode, onloaded) {
        let replacement = document.createElement("div");
        replacement.innerHTML = addScriptsNode.textContent;
        let callBack = null;
        let dataCallBack = addScriptsNode.getAttribute('data-ds-callback');
        if (dataCallBack && dataCallBack.length > 0) {
            callBack = window[dataCallBack];
        }
        let dataDefer = addScriptsNode.getAttribute('data-ds-defer');

        var head = document.getElementsByTagName("head")[0];
        //(head || document.body).appendChild(replacement);
        if (head) {
            var listScripts = [];
            var listContentScripts = [];
            for (var j = 0; j < replacement.children.length; j++) {
                var addNode = replacement.children[j];
                if (addNode.tagName.toLowerCase() == "script") {
                    var src = addNode.src;
                    if (src && src.length > 0) {
                        //console.log('** ' + addScriptsNode.id + '** added ** ' + src);
                        listScripts.push(src);
                        continue;
                    } else if (addNode.innerHTML && addNode.innerHTML.length > 0) {
                        listContentScripts.push(addNode.innerHTML);
                        continue;
                    }
                }
                head.appendChild(addNode);
            }

            var callbackLoadScripts = function () {
                //insertContentScripts(listContentScripts);

                //console.log("listScripts " + (addScriptsNode.id || addScriptsNode.className || ''));
                if (callBack && typeof callBack === 'function')
                    callBack();
                if (onloaded && typeof onloaded === 'function')
                    onloaded();
            };
            if (listScripts.length > 0) {
                //console.log("listScripts length = " + listScripts.length);
                loadScripts(listScripts, callbackLoadScripts, { defer: dataDefer === 'true' });
            } else {
                callbackLoadScripts();
            }
        } else {
            document.body.appendChild(replacement);
        }
        addScriptsNode.parentElement.removeChild(addScriptsNode);
    };

    var loadDeferredScriptGroups = function (delay) {
        if (delay && delay > 0) {
            //console.log('loadDeferredScriptGroups delay ' + delay + 'msec');
            setTimeout(loadDeferredScriptGroups, delay);
            return;
        }

        //var elements = document.getElementsByClassName("deferred-scripts");
        var elements = Array.prototype.slice.call(document.getElementsByClassName("deferred-scripts"));
        elements.sort(function (a, b) {
            return +a.getAttribute('data-ds-order') - +b.getAttribute('data-ds-order');
        });

        var counter = 0;
        var groupDelay = null; // set delay between groups if need it
        var load_deferred_script_group = function() {
            if (elements && elements.length > 0 && elements.length > counter) {
                if (groupDelay && groupDelay > 0) {
                    setTimeout(function () {
                        loadDeferredScriptGroup(elements[counter], onloaded);
                    }, groupDelay);
                } else {
                    loadDeferredScriptGroup(elements[counter], onloaded);
                }
            } else {
                InitAllScripts();
            }
        };

        var onloaded = function () {
            //console.log('loadDeferredScriptGroups onloaded[' + counter + ']');
            counter++;
            // if (elements.length > counter) {
            //     if (groupDelay && groupDelay > 0) {
            //         setTimeout(function () {
            //             loadDeferredScriptGroup(elements[counter], onloaded);
            //         }, groupDelay);
            //     } else {
            //         loadDeferredScriptGroup(elements[counter], onloaded);
            //     }
            // } else {
            //     InitAllScripts();
            // }
            load_deferred_script_group();
        };

        // if (groupDelay && groupDelay > 0) {
        //     setTimeout(function () {
        //         loadDeferredScriptGroup(elements[counter], onloaded);
        //     }, groupDelay);
        // } else {
        //     loadDeferredScriptGroup(elements[counter], onloaded);
        // }
        load_deferred_script_group();

    };

    var loadDeferredScripts = function (delay) {
        if (delay && delay > 0) {
            //console.log('loadDeferredScripts delay ' + delay + 'msec');
            setTimeout(loadDeferredScripts, delay);
            return;
        }

        //var elements = document.getElementsByClassName("deferred-scripts");
        var elements = Array.prototype.slice.call(document.getElementsByClassName("deferred-scripts"));
        elements.sort(function (a, b) {
            return +a.getAttribute('data-ds-order') - +b.getAttribute('data-ds-order');
        });

        for (var i = 0; i < elements.length; i++) {
            //let addScriptsNode = elements.item(i);
            let addScriptsNode = elements[i];
            let replacement = document.createElement("div");
            replacement.innerHTML = addScriptsNode.textContent;
            let callBack = null;
            let dataCallBack = addScriptsNode.getAttribute('data-ds-callback');
            if (dataCallBack && dataCallBack.length > 0) {
                callBack = window[dataCallBack];
            }
            let dataDefer = addScriptsNode.getAttribute('data-ds-defer');

            var head = document.getElementsByTagName("head")[0];
            //(head || document.body).appendChild(replacement);
            if (head) {
                var listScripts = [];
                for (var j = 0; j < replacement.children.length; j++) {
                    var addNode = replacement.children[j];
                    if (addNode.tagName.toLowerCase() == "script") {
                        var src = addNode.src;
                        if (src && src.length > 0) {
                            //console.log('** ' + addScriptsNode.id + ' ** added ** ' + src);
                            listScripts.push(src);
                            continue;
                        }
                    }
                    head.appendChild(addNode);
                }
                if (listScripts.length > 0) {
                    //console.log("listScripts length = " + listScripts.length);
                    loadScripts(listScripts, function () {
                        //console.log("listScripts " + (addScriptsNode.id || addScriptsNode.className || ''));
                        if (callBack && typeof callBack === 'function')
                            callBack();
                    }, { defer: dataDefer === 'true' });
                }
            } else {
                document.body.appendChild(replacement);
            }
            addScriptsNode.parentElement.removeChild(addScriptsNode);
        }
    };
    //var raf = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
    //    window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
    //if (raf) raf(function () { window.setTimeout(loadDeferredScripts, 0); });
    //else window.addEventListener('load', loadDeferredScripts);
    window.addEventListener('load', loadDeferredScriptGroups);
}).call(this);

function InitAllScripts() {
    var handleInitScript = function () {
        console.log('>>> InitAllScripts Start <<<');
        AppLoader.setLoaded();
    };

    var tasks = [];
    tasks.push(handleInitScript);
    // Main scripts initialization

    /*
    tasks.push(InitScript_1);
    tasks.push(InitScript_2_updateUI);
    tasks.push(InitScript_3_ready);
    tasks.push(InitScript_4_RetinaGraphics);
    tasks.push(InitScript_5_Headroom);
    tasks.push(InitScript_6_BackToTop);
    tasks.push(InitScript_7_HeaderSearch);
    tasks.push(InitScript_8_Sorting);
    tasks.push(InitScript_9_PagNav);
    tasks.push(InitScript_10_BoxWithSubMenu);
    tasks.push(InitScript_11_LeftMenu_StickySidebar);
    tasks.push(InitScript_12_RoundHeaderCaptionAndImage);


    //tasks.push(InitScript_13_ScrollToRequestedTarget);

    // Some extra initialization
    tasks.push(InitScriptExtend);
    if(AppData && AppData.Plugins && AppData.Plugins.Mapbox) {
        //tasks.push(InitScript_gmaplogic);
        tasks.push(InitScript_mapboxlogic);
    }
    tasks.push(InitScript_packagelogic);
    //tasks.push(InitScript_SearchBarSelectors);
    tasks.push(InitScript_searchlogic);
    tasks.push(InitScript_searchlogic_extend);
    tasks.push(InitScript_mytriplogic);
    tasks.push(InitScript_checkoutlogic);*/

    var callbackInitScript = function () {
        console.log('>>> InitAllScripts End <<<');

        AppLoader.trigger('InitAllScripts');
        if(!AppLoader.isReady()) {
            AppLoader.setReady();
        }
    };

    var initializer = function (func, handler) {
        if (typeof func !== 'function') {
            //if (func) {
            //    console.log('>>> initializer: ' + func.name + ' is not a function');
            //} else {
            //    console.log('>>> initializer: ' + func.name + ' is null or undefined');
            //}
            handler();
        }
        //console.log('>>> initializer: ' + func.name);

        let executeTask = function () {
            func();
            handler();
        };

        if (window.requestAnimationFrame && false) {
            //let rafTask = function () {
            //    window.setTimeout(executeTask, 0);
            //};
            //window.requestAnimationFrame(rafTask);
            window.requestAnimationFrame(executeTask);
        } else {
            if (window.requestIdleCallback && false) {
                // requestIdleCallback supported
                let backgroundTask = function (deadline) {
                    // run next task if possible
                    while (deadline.timeRemaining() > 0) {
                        func();
                        //handler();
                    }
                    window.requestIdleCallback(handler);
                };
                window.requestIdleCallback(backgroundTask);
            } else {
                // no support requestIdleCallback -- run all tasks with timeouts
                let delayTask = /MSIE ([6-9]+\.\d+);/.test(navigator.userAgent) ? 8 : 0;
                window.setTimeout(executeTask, delayTask);
            }
        }
    };
    (function executeInitScript() {
        if (tasks.length != 0) {
            initializer(tasks.shift(), executeInitScript);
        } else {
            callbackInitScript && callbackInitScript();
        }
    })();

}